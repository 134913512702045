
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.login-container {
    display: flex;
    justify-content: flex-start;
    height: 100vh;
}

.login-left {
    flex: 2 1 0;
    background-size: cover;
    background-image: url("../images/login-background-mhac.jpg");
}

.login-right {
    flex: 1 0 0;
    display: flex;
    justify-content: center;
    text-align: center;
}

.login-box {
    justify-content: center;
    margin: 5px;
}

.login-box h2 {
    font-weight: bolder;
}

.login-box div {
    margin-bottom: 5px;
}

.overflow-div {
    inline-size: 100%;
    overflow-wrap: break-word;
    height: 350px;
    width: 100%;
}

.alarm-viewer {
    width: calc(100vw - 300px);
    height: calc(100vh - 100px);
}

.logo-vertical {
    transform: rotate(-90deg);
    margin-left: -82px;
    margin-bottom: 60px;
}